@import "pallete.scss";

.data-header {
	display: block;
	margin: 20px 0 5px;
	justify-content: space-between;
	align-items: center;

	h1 {
		display: inline-block;
		margin: 0;
	}

	svg {
		vertical-align: top;
		margin: 0 5px;
	}

	@include mobile {
		flex-wrap: wrap;

		.buttons {
			width: 100%;
		}
	}

	.buttons {
		align-self: normal;
	}

	button {
		margin: 0 0;
		border: none;
		border-radius: 3px;
		padding: 10px 9px 7px;
		background: transparent;
		color: var(--button-brand);

		&:hover {
			background-color: #1c9126;
		}
	}

	.parent-data {
		display: flex;
		transition: 0.2s all linear;
		background-color: var(--background-box);
		cursor: pointer;

		&.closed {
			height: 41px;
			overflow: hidden;
		}

		&.open {
			svg {
				transform: rotate(180deg);
			}
		}

		svg {
			transition: 0.2s all linear;
			padding: 5px;
			font-size: 30px;
			color: #0e7912;
		}

		.entry {
			margin: 3px 0;

			.key {
				font-weight: bold;
			}

			.value {
				margin: 0 10px;
			}
		}
	}
}

.master-table {
	width: 100%;

	@include mobile {
		overflow-x: auto;
	}

	.scroller {
		min-width: 800px;
	}
}

table {
	width: 100%;
	border-spacing: 0;
	table-layout: fixed;
	background-color: var(--background-box);

	button svg {
		margin: 3px;
	}

	a {
		text-decoration: inherit;
		color: var(--dark-brand);
	}

	thead {
		background-color: var(--base-brand);
		color: #fff;
	}

	select,
	input {
		display: block;
		width: 100%;
		padding: 3px;
		box-sizing: border-box;
		border: 1px solid var(--border);
		background-color: transparent;
		font-family: sans-serif;
		color: var(--base-text);
	}

	select {
		font-size: 16px;
	}

	th,
	td {
		overflow: auto;
		padding: 3px 6px;
		overflow-wrap: break-word;

		button {
			background-color: transparent !important;
			color: var(--button-brand) !important;
			border-radius: 30px;
			padding: 8px;
			border: none;

			&:hover {
				color: #fff;
			}
		}

		input[type='checkbox'] {
			display: inline-block;
		}

		.actions {
			display: flex;

			> *,
			button {
				flex-grow: 0;
				margin: 0;
				padding: 5px;
				text-align: center;
			}
		}

		.cover-image {
			width: 120px;
			height: 120px;
			border-radius: 20px;
		}
	}

	th select {
		background-color: transparent;
		color: #fff;
		border: none;
	}

	input[type='checkbox'] {
		display: block;
		margin: 5px auto;
		width: 20px;
		height: 20px;
	}

	tbody tr:nth-child(odd) {
		background-color: var(--background-accent-store);
	}

	.nested-attribute {
		img {
			height: 14px;
		}

		.value {
			margin: 0 3px;
		}

		.main {
			font-size: 16px;
			font-weight: bold;
		}

		.expanded {
			font-size: 14px;
		}

		.old {
			color: var(--box-accent-negative);

			img,
			svg {
				filter: hue-rotate(255deg);
			}
		}
	}
}

.badge {
	display: inline-block;
	padding: 4px;
	margin: 4px;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	padding: 0.25em 0.4em;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
}

.no-results {
	color: var(--base-text);
	background-color: var(--background-box);
	font-size: 28px;
	padding: 10px 0;
	margin: 0 0 10px;

	svg {
		font-size: 80px;
		display: block;
		margin: 0 auto;
	}

	h3 {
		margin: 0;
		font-weight: 300;
	}

	text-align: center;
}

#data-table {
	//border: 0 solid var(--base-brand);
	//border-width: 0 1px 1px;

	th {
		overflow: hidden;

		> span {
			display: flex;
			justify-content: space-between;

			.text {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;
				text-align: left;
				padding-bottom: 2px;

				@include mobile {
					font-size: 14px;
				}
			}

			svg {
				flex-shrink: 0;
				color: #f7f7f7;
			}
		}

		.sort {
			cursor: pointer;
		}
	}
}

.attribute-search {
	.attr-form-field {
		box-sizing: border-box;
		display: flex;
		border-bottom: 1px solid rgba(255, 255, 255, 0.31);

		svg {
			vertical-align: bottom;
			background-color: transparent;
			padding: 5px 6px 6px;
			font-size: 12px;
			flex-shrink: 0;
		}

		input {
			background-color: transparent;
			border: transparent;
			box-sizing: border-box;
			color: #fff;
			padding: 4px;
			flex-grow: 1;
			outline: none;
		}
	}
}

.table-control {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 10px 0;
}

.pagination {
	display: block;
	text-align: center;
	margin: 10px 0 10px;

	.pagination-label {
		display: inline-block;
		vertical-align: middle;
		margin: 0 10px;

		> span {
			display: block;
			text-align: center;
		}

		.results {
			font-size: 14px;
		}
	}

	button {
		background-color: transparent;
		color: var(--dark-brand);
		border: none;
		font-size: 22px;
		padding: 4px 6px;
		border-radius: 50%;
		cursor: pointer;
		user-select: none;
		outline: none;
		vertical-align: middle;
		margin: 0;

		&:disabled {
			color: #888;
			background-color: rgba(0, 0, 0, 0.041);
			cursor: auto;

			&:hover {
				color: #888;
				background-color: #fff;
			}
		}

		svg {
			vertical-align: text-bottom;
			margin: 0;
		}

		&:hover {
			color: #fff;
			background-color: var(--border-brand);
		}

		&:active {
			color: #fff;
			background-color: var(--dark-brand);
		}
	}
}

.table-actions {
	display: flex;
	flex-wrap: wrap;

	button {
		display: flex;
	}

	.page-attributes {
		margin: 0 5px;
		align-self: flex-end;

		@include mobile {
			flex-grow: 1;
			flex-basis: 45%;
			margin: 10px;
		}

		input,
		select {
			display: block;
			padding: 6px 10px;
			box-sizing: border-box;
			border: 1px solid var(--button-brand);
			background-color: transparent;
			font-family: sans-serif;
			font-size: 16px;
			color: var(--button-brand);
			font-weight: bold;
			border-radius: 3px;
			height: 32px;
			width: 100%;
		}
	}
}

.date-attributes {
	margin: 0 15px;
	align-self: center;

	@include mobile {
		flex-grow: 1;
		flex-basis: 45%;
		margin: 10px;
	}
	label{
		font-family: sans-serif;
		font-size: 16px;
		color: #333;
		font-weight: bold;
	}
	input,
	select {
		display: flex;
		padding: 6px 10px;
		box-sizing: border-box;
		border: 1px solid var(--button-brand);
		background-color: transparent;
		font-family: sans-serif;
		font-size: 16px;
		color: var(--button-brand);
		font-weight: bold;
		border-radius: 3px;
		height: 32px;
		width: 100%;
	}
}
