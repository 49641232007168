.sponsor-dashboard {
	background: linear-gradient(141deg, #0e7a12 0%, #39911b 50%, #6eac22 100%);
	min-height: 100vh;
	padding: 0 0 20px;

	.nav-bar {
		padding: 10px 3vw;

		.container {
			display: flex;
			max-width: 1200px;
			justify-content: space-between;
			width: 100%;
			margin: 0 auto;
		}

		img {
			max-width: 100px;
		}

		display: flex;
		background-color: #0003;
	}
}
