.profile {
	max-width: 800px;
	margin: 0 auto 30px;

	.data-header {
		font-size: 16px;
		border-bottom: 1px solid var(--border);
	}

	.picture-form {
		margin: 0 auto;
		text-align: center;
	}

	.user-picture {
		position: relative;
		transition: 0.2s all cubic-bezier(0.445, 0.05, 0.55, 0.95);

		&:hover {
			img {
				filter: brightness(0.5);
			}

			.change-photo {
				bottom: calc(50% - 10px);
			}
		}
	}

	.change-photo {
		position: absolute;
		bottom: 20px;
		left: 0;
		right: 0;
		color: #fff;
		text-shadow: 1px 1px 5px #000;
		text-transform: uppercase;
		transition: 0.2s all cubic-bezier(0.445, 0.05, 0.55, 0.95);

		svg {
			filter: drop-shadow(1px 1px 5px #000);
			margin: 0 2px 0 0;
		}

		cursor: pointer;
	}

	img {
		width: 200px;
		height: 200px;
		border: solid 1px var(--border);
		border-radius: 50%;
		//object-fit: cover;
		cursor: pointer;
		text-align: center;
		display: block;
		transition: 0.2s all linear;
	}

	.header {
		h3 {
			display: inline-block;
			margin: 7px 0;
		}

		.action-buttons {
			float: right;

			button {
				font-size: 18px;
				margin: 0 0;
				border: none;
				border-radius: 3px;
				padding: 10px 9px 7px;
				background: transparent;
				color: #0e7912;

				&:hover {
					background-color: #06741126;
				}
			}
		}
	}

	.card {
		//border: solid 2px var(--border);
		padding: 10px;
		margin: 10px;
		border-radius: 10px;
		background-color: var(--background-box);
		width: 100%;

		.panel-body {
			margin: 5px;
		}

		.picture-panel-body {
			display: flex;
		}

		.data-field {
			.label {
				.icon {
					width: 80%;
					height: 80%;
					margin-right: 6px;
				}

				font-weight: bold;
				font-size: 17px;
			}

			.value {
				padding: 3px 0;
				margin: 0 25px 2px 0;
				font-size: 17px;
			}
		}
	}
}
