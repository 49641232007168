@import "pallete.scss";

.categories {
	display: flex;

	.category {
		flex-basis: 33%;
		padding: 10px 10px 0 0;

		.title {
			margin: 0 0 10px;
			font-weight: normal;
		}
	}

	.subcat label {
		display: block;

		input {
			margin: 0;
		}
	}
}

.image-input {
	padding: 0 10px;
	display: inline-block;
	width: 20%;
	min-height: 100px;
	margin: 0 0 15px;

	label {
		.placeholder {
			padding: 20px 20px;
			min-height: 40px;
			display: flex;
			align-items: center;
		}

		svg {
			margin: 0 10px;
		}
	}

	.image {
		width: 80px;
		height: 80px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		border-radius: 10px;
		margin: 3px 10px 0;
	}
}

.success-publishing {
	padding: 30px;

	svg {
		color: green;
		display: block;
		margin: 0 auto;
		font-size: 80px;
	}

	h1 {
		margin: 25px 0 auto;
		font-weight: normal;
		text-align: center;
	}
}

.tos {
	display: block;
	margin: 15px 15px 15px;
	color: var(--base-subtext);

	a {
		color: green;
	}
}
