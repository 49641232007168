.ai-generator {
  margin: 15px !important;
}
.parameters{
  display: flex;
  margin: 7px 0 0;
}
.content{
  input {
    margin: 0 0 5px;
  }
}