@import "./pallete.scss";

@font-face {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  src: local("Proxima Nova"), local("ProximaNova"),
    url("./ProximaNova.ttf") format("truetype");
}

:root {
  // Color Scheme
  --background-plain: #fff;
  --background-accent: #eee;
  --background-accent-store: #fafafa;
  --background-box: #fff;
  --base-text: #333;
  --base-subtext: #555;
  --disabled: #86898e;
  --border: #dee1e5;
  --base-brand: #008000;
  --border-brand: #006400;
  --dark-brand: #006400;
  --button-brand: #228b22;
  --box-positive: #e3f5e5;
  --box-neutral: #dceff4;
  --box-negative: #f7dfdf;
  --box-accent-positive: #298707;
  --box-accent-negative: #9d0823;
  --blue: #0c47dc;
  --blue-active: #233c7c;
  --background-modal: #fff;
  --hover-accent: rgba(0, 0, 0, 0.07);

  --box-accent-positive-rgb: 41, 135, 7;
  --box-accent-negative-rgb: 157, 8, 35;
  --blue-rgb: 12, 71, 220;

  &.dark {
    --background-plain: #111;
    --background-accent: #18191a;
    --background-accent-store: #18191a;
    --background-box: #242526;
    --base-text: #eaeaea;
    --base-subtext: #dedcdc;
    --disabled: #969aa1;
    --border: #242526;
    --base-brand: #242526;
    --border-brand: #067411;
    --dark-brand: #31bb38;
    --button: #228b22;
    --button-brand: #228b22;
    --box-positive: #298707;
    --box-neutral: #1368b3;
    --box-negative: #9d0823;
    --box-accent-positive: #0ba41d;
    --box-accent-negative: #df2c2c;
    --blue: #233c7c;
    --blue-active: #0c47dc;
    --background-modal: #18191a;
    --hover-accent: rgba(255, 255, 255, 0.07);

    --box-accent-positive-rgb: 11, 164, 29;
    --box-accent-negative-rgb: 223, 44, 44;
    --blue-rgb: 35, 60, 124;
  }
}

body {
  color: var(--base-text);
  background-color: var(--background-plain);
  font-family: "Proxima Nova", "Open Sans", sans-serif;
  overscroll-behavior: none;
}

// // Disable scroll bounce on safari
// body, html {
//   position: fixed;
//   min-width: 100vw;
//   min-height: 100vh;
// }

a {
  overflow: hidden;
  color: inherit;
  text-decoration: inherit;
}

svg {
  vertical-align: text-bottom;
}

.hidden {
  display: none !important;
}

.modal-view {
  .modal-content {
    max-width: 500px;
    padding: 0 40px;
    left: calc(50% - 250px) !important;

    .text {
      font-size: 20px;
      margin: 0 0 20px;
      padding: 40px 0 0;
      color: var(--base-subtext);
    }
  }

  .buttons {
    text-align: right;
    display: flex;

    button:not(.lvx-button) {
      color: var(--base-subtext);
      background-color: #fff;
      border: none;
      margin: 0 0 12px;
      text-transform: uppercase;
    }
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: saturate(1.2) blur(2px);
  width: 100%;
  height: 100%;
  z-index: 99;

  .modal-content {
    @include mobile {
      left: 10%;
      top: 70px;
      width: 80%;
    }

    position: fixed;
    left: 25%;
    top: 10vh;
    background-color: var(--background-modal);
    width: 50%;
    max-height: 80vh;
    margin: 0 auto;
    overflow-y: auto;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.125);
  }

  .buttons {
    margin-bottom: 10px;

    button.confirm {
      color: var(--button-brand);

      &:disabled {
        color: #a8aca5;
      }
    }
  }

  textarea {
    resize: none;
    height: 70px;
    color: var(--base-text);
    background-color: var(--background-box);
    font-size: 14px;
    padding: 8px;
  }
}

textarea {
  width: 100% !important;
  border: 1px solid var(--border);
  margin: 0 0 15px;
  box-sizing: border-box;
  background-color: var(--background-box);
}

.transparent {
  opacity: 0.1 !important;
}

.Center {
  display: flex;
  height: 100vh;

  > * {
    overflow: auto;
  }

  .View {
    flex-grow: 1;

    > * {
      padding: 20px 3vw;

      @include mobile {
        padding-top: 50px;
      }
    }
  }

  aside {
    min-width: 240px;
    max-width: 240px;

    @include mobile {
      min-width: 100%;
      max-width: 100%;
      transition: 0.2s min-width linear;

      &.closed {
        min-width: 0 !important;
        max-width: 0 !important;
      }

      .parrot {
        position: fixed;
        width: 100%;
        background-color: var(--base-brand);
        z-index: 99;
      }

      svg {
        display: inline-block !important;
      }

      .user-card-area {
        padding: 50px 0 0;
      }
    }
  }
}

.rtl {
  direction: rtl;

  .livox-logo img,
  svg {
    transform: scaleX(-1);
  }
}

.text-clip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.master-form {
  width: 500px;
  margin: 0 auto;
  font-size: 17px;

  button:not(.lvx-button) {
    background-color: var(--button-brand);
    color: white;
  }
}

.checkbox {
  label {
    display: flex;

    span {
      flex-grow: 1;
      padding-top: 10px;
    }
  }
}

.Media {
  width: 100px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 20px;
  object-fit: contain;
}

.play-media {
  height: 10px;
}

.form {
  legend {
    font-size: 26px;
    margin: 15px 0;
    padding: 0 0 4px;
    border-bottom: 1px solid var(--border);
  }

  input,
  select {
    display: block;
    width: 100%;
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    margin: 4px 0 15px;
    border: 1px solid var(--border);
    background-color: var(--background-box);
    color: var(--base-text);
  }

  input:disabled {
    color: rgb(84, 84, 84);
    cursor: default;
    background-color: rgb(235, 235, 228);
  }

  .form-errors {
    margin: 5px 10px;
    background-color: #d22828;
    color: white;
    padding: 14px 7px;
    text-align: center;
  }

  label {
    font-size: 17px;
    margin: 0 0 3px;
  }

  .form-buttons {
    display: flex;
  }

  button:not(.lvx-button) {
    border: transparent;
    display: inline-block;
    margin: 0 15px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    flex-grow: 1;
    padding: 10px 10px 7px;
    user-select: none;
  }

  button.cancel {
    background-color: transparent;
    color: #1b9125;
    border: none;
    outline: none;
  }

  .radio-button {
    display: flex;
    border: 1px solid var(--button-brand);
    border-radius: 3px;

    label {
      transition: 0.15s all linear;
      flex-grow: 1;
      color: var(--button-brand);
      cursor: pointer;
      text-align: center;

      &:hover {
        background-color: rgba(0, 0, 0, 0.048);
      }

      &:not(:last-child) {
        border-right: 1px solid var(--border-brand);
      }

      span {
        transition: 0.1s all ease-in-out;
        padding: 10px;
      }
    }

    input {
      width: auto;
      display: none;
      margin: 0 5px;
      vertical-align: middle;

      &:checked + span {
        background-color: var(--border-brand);
        color: #fff;
        font-weight: bold;
      }
    }
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    vertical-align: sub;
    margin: 10px;
    display: inline-block;
  }
}

.form-creation {
  max-width: 700px;
  margin: 0 auto 30px;
  padding: 0 30px;

  .field {
    vertical-align: top;
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    padding: 0 10px;

    @include mobile {
      min-width: 100% !important;
    }
  }

  .label {
    margin: 0 0 7px;

    label {
      display: block;
      //border-bottom: 1px solid var(--border);
      padding: 0 0 5px;
    }

    span {
      font-size: 20px;
    }
  }
}

.livox button:not(.lvx-button) {
  transition: all 0.16s ease-in-out;
  border: transparent;
  display: inline-block;
  margin: 0 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  flex-grow: 1;
  border-radius: 3px;
  padding: 10px 10px 7px;
  background: var(--button-brand);
  color: #fff;

  svg {
    vertical-align: text-top;
    margin: 0 3px;
  }

  &:hover {
    background-color: var(--border-brand);
    color: #fff;
  }

  &:disabled {
    background: var(--background-accent);
    color: var(--base-text);
    cursor: normal;
  }
}

.mobile-hidden {
  @include mobile {
    display: none !important;
  }
}

button.export {
  background: transparent;
  color: var(--button-brand);
  border: 1px solid var(--border-brand);
  margin: 0 3px;
  padding: 7px 10px 5px;

  @include mobile {
    display: none !important;
  }

  &:hover {
    background-color: #1c9126;
    color: #fff;
    border: 1px solid #067310;
  }
}

.block {
  display: block !important;
}