@import 'pallete.scss';

.livox-app .nav-bar {
	justify-self: stretch;
	color: white;
	background-color: #0002;

	.speech-recognizer {
		font-size: 18px;
		align-self: center;
		margin: 0 5px;
		background-color: rgba(7, 7, 7, 0.194);
		padding: 8px;
		border-radius: 50%;
		cursor: pointer;

		&.LISTENING {
			color: #ff3e3e;
			color: #03d01b;
		}
	}

	&.hide {
		height: 0;
		overflow: hidden;
	}

	.container {
		padding: 0 1.5vw;
		margin: 0 auto;
		display: flex;
		justify-content: space-around;
	}

	.logo {
		align-self: center;

		img {
			width: 40px;
		}
	}

	.controls {
		flex-grow: 1;
	}

	.controls ul {
		display: flex;
		list-style-type: none;
		flex-grow: 1;
		margin: 0;
		padding: 0;
		max-width: 600px;
		margin: 0 auto;

		.start {
			background-color: rgba(0, 0, 0, 0.351);
			padding: 5px 0 5px;

			svg {
				font-size: 30px;
			}
		}

		li {
			text-align: center;
			flex-basis: 0;
			flex-grow: 1;
			user-select: none;
			border-radius: 25px;

			&.start a.active {
				border-bottom: none;
			}
		}

		a {
			padding: 10px 25px 4px;
			margin: 0 0;
			font-size: 18px;
			font-weight: bold;
			cursor: pointer;
			display: block;
			color: #ffffffb8;
			text-align: center;

			&.active {
				color: #fff;
				border-bottom: 4px solid rgba(255, 255, 255, 0.721);
			}

			svg {
				font-size: 19px;
				padding: 0 0 4px;
				display: block;
				margin: 0 auto;
			}
		}
	}

	.user-switcher {
		position: relative;
		align-self: center;

		@include mobile {
			display: none;
		}

		&:hover {
			.submenu {
				display: block !important;
			}

			.current {
				//visibility: hidden;
			}
		}

		.current {
			display: flex;
			align-items: center;

			svg {
				margin: 0 0 0 3px;
			}

			.user-name {
				display: none;
			}
		}

		.user-card {
			display: flex;
			align-items: center;

			.user-avatar {
				margin: 0 5px;

				img {
					background-color: #fff;
				}
			}
		}

		.submenu {
			position: absolute;
			display: none;
			left: -110px;
			width: 180px;
			color: #333;
			z-index: 3;
			margin: 0;
			list-style-type: none;
			padding: 0;

			li {
				padding: 10px 10px;
			}

			.current-more-info {
				margin: 0 0 10px;
				display: none;

				.user-name {
					display: none;
				}
			}

			.hover-content {
				background-color: #fff;
				box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
				padding: 10px;
				border-radius: 10px;

				.others {
					border-bottom: 1px solid var(--border);
					padding: 0 0 10px;
					margin: 0 0 10px;

					.user-card {
						padding: 5px;
						cursor: pointer;
						border-radius: 5px;

						.user-name {
							margin: 0 5px;
						}

						&:hover {
							background-color: var(--hover-accent);
						}
					}
				}

				a {
					display: block;
					padding: 5px;
					font-size: 14px;
					color: #000;

					&:hover {
						background-color: var(--hover-accent);
					}
				}
			}
		}
	}
}

@include mobile {
	.livox-app .nav-bar {
		order: 2;

		.logo {
			display: none;
		}

		.controls ul {
			max-width: none !important;
			align-items: center;

			li {
				padding-bottom: env(safe-area-inset-bottom) !important;
			}

			a {
				padding: 12px 0 12px !important;

				svg {
					display: inline !important;
					padding: 0 !important;
					vertical-align: text-bottom;
					font-size: 28px !important;
				}

				&.active {
					border-bottom: none !important;
				}
			}

			li.start {
				background-color: transparent !important;
				padding: 0;
			}
		}

		.current-user svg {
			display: none;
		}

		.user-name {
			color: #555 !important;
		}

		.user-switcher {
			width: 20%;
			position: unset !important;

			.current {
				visibility: visible !important;

				svg {
					display: none;
				}

				.user-card {
					margin: 0 auto;

					img {
						height: 25px;
					}
				}
			}

			.submenu {
				left: 0;
				right: 0;
				top: auto;
				bottom: 43px;
				width: auto;
				background-color: #fff;
			}
		}
	}
}
