.settings {
	padding: 0 20px;

	.setting {
		display: flex;
		justify-content: space-between;
		margin: 12px 0;

		.stepper {
			.count {
				margin: 0 7px;
				font-family: monospace;
				font-size: 18px;
				vertical-align: text-bottom;
				width: 21px;
				display: inline-block;
				text-align: center;
			}

			svg {
				margin: 0 3px;
				font-size: 20px;
				color: var(--dark-brand);
				vertical-align: text-bottom;
				cursor: pointer;
			}
		}
	}

	.header {
		margin: 0;
	}

	.color-toggler {
		cursor: pointer;
		margin: 12px 0;
	}

	.flags {
		margin: 0;

		img {
			width: auto;
			height: 20px;
			margin: 0 4px;
			border: none;
		}
	}
}
