@import "pallete.scss";
@import "~mobile-drag-drop/default.css";

.all-cards {
    display: grid;
    padding: 10px 10px 20px;
    border: none;
    gap: 0.5vw;

    @media print
    {
      page-break-after:auto;
      page-break-inside:avoid; 
      page-break-after:auto;
    }

    .item.Back {
      grid-row-start: var(--vertical-items-ammount);
      grid-column-start: 1;
      display: flex;
      border: 5px solid black;
      border-radius: 10px;
    }

    .item.Next {
      grid-row-start: var(--vertical-items-ammount);
      grid-column-start: var(--horizontal-items-ammount);
      display: flex;
      border: 5px solid black;
      border-radius: 10px;
    }    
    
    .item {
      display: flex;
      flex-direction: column;
      align-content: center;    
      padding: 15px;
      //height: 100%;
      
      color: #333;
      font-size: 20px;      
      text-align: center;

      img {
        object-fit: contain;
        width: 100%;
        height: 85%;
      }

      .written-text-print {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
    }
  	
}