@import "pallete.scss";

.sponsor-overview {
	.cards {
		max-width: 800px;
		margin: 20px auto;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

		@include mobile {
			grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
		}
	}

	.sponsor-header {
		color: #fff;
		text-align: center;
		text-shadow: 0 1px 5px #000;

		h2 {
			margin: 2em 0 1em;
			font-size: 40px;
		}

		.stats {
			font-size: 24px;
		}

		.instructions {
			margin: 0;
		}
	}

	.customer-card {
		flex-basis: 0;
		background-color: #fff;
		color: #333;
		border-radius: 10px;
		padding: 20px 20px 0;
		box-shadow: 1px 3px 5px 0 #2323238f;
		margin: 10px;

		.header {
			flex-grow: 1;
		}

		&:hover {
			box-shadow: 2px 4px 5px 2px #232323af;
			cursor: pointer;
			background-color: #fff;
		}

		display: flex;
		justify-content: space-between;
		padding: 15px;

		h2 {
			margin: 0 0 10px;
			font-size: 20px;
		}

		p {
			margin: 0;
		}

		.gauge-loader {
			width: 75px;
			height: 75px;
			background-color: var(--background-accent);
			border-radius: 50%;
		}
	}
}
