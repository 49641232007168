$mobile-width: 1024px;
$cellphone-width: 425px;

@mixin mobile {
	@media (max-width: #{$mobile-width}) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: #{$mobile-width}) and (min-width: #{$cellphone-width}) {
		@content;
	}
}

@mixin cellphone {
	@media (max-width: #{$cellphone-width}) {
		@content;
	}
}
