@import 'pallete.scss';

.livox-studio .items {
	display: flex;
	flex-wrap: wrap;
	user-select: none;

	.item {
		width: calc(100% / var(--horizontal-items-ammount));
		transition: width 0.1s linear;
		display: flex;
		position: relative;
		color: #333;
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
	}

	.gutter {
		flex-grow: 0;
		flex-shrink: 0;
		position: absolute;
		height: 100%;
		width: 20px;

		&.over {
			background-color: #98999a;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(59, 255, 44, 0) 30%, rgba(154, 154, 154, 1) 50%, rgba(59, 255, 44, 0) 70%, rgba(255, 255, 255, 0) 100%);
		}

		&.left {
			left: -10px;
		}

		&.right {
			right: -10px;
		}
	}

	li {
		list-style-type: none;
		border: 1px solid rgba(0, 0, 0, 0.12);
		border-radius: 10px;
		padding: 10px;
		background-color: #fff;
		width: calc(100% - 40px);
		margin: 10px auto;
		position: relative;
		cursor: pointer;
		transition: opacity 0.2s linear;

		&:focus {
			outline: none;
			border-color: #288aec;
		}

		&:active {
			border-color: rgba(0, 0, 0, 0.12);
		}

		&.expanded {
			z-index: 99;
			border-radius: 0;

			.text {
				font-size: calc(var(--card-font) / 2);
				overflow: auto !important;
				text-overflow: unset !important;
				white-space: normal !important;

				@include mobile {
					font-size: calc(var(--card-font) / 3);
				}
			}
		}

		&.over {
			box-shadow: inset 0 0 18px -4px rgba(0, 0, 0, 0.5);

			.image {
				pointer-events: none;
			}
		}

		&.dragging {
			opacity: 0.4;
		}

		&.editing {
			//border-width: 3px;
			//padding: 20px;
			border-color: #288aec;
			//outline: 5px solid #288aec;
			box-shadow: 0 0 8px 1px rgb(42, 108, 243);
		}

		&.has-children {
			border-bottom-width: 4px;
		}

		&.new-item {
			cursor: pointer;
			border: 3px dashed #808080;
			color: var(--base-text);
			color: #333;
			border-radius: 10px;

			span {
				font-size: 30px;
				text-align: center;
				display: block;
				margin: 7px 0 0;
			}

			&:focus {
				border-color: #288aec !important;
			}
		}

		.images {
			display: flex;
			align-items: center;
			margin: 0 auto 5px;

			svg {
				font-size: 24px;
				color: rgba(0, 0, 0, 0.8);
				margin: 5px;
			}
		}

		.image {
			display: block;
			max-height: 100px;
			min-height: 100px;
			max-width: 100%;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			flex-grow: 1;
			color-adjust: exact;
		}

		.written-text {
			font-weight: bold;
			display: block !important;
			margin: 0 auto;
			font-size: 16px;
			text-align: center;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: block;
			text-align: center !important;
		}

		.spoken-text {
			color: #555;
			font-size: 14px;
			display: flex;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			svg {
				margin: 0 5px !important;
				font-size: 13px !important;
				vertical-align: text-bottom;
			}
		}

		.attributes {
			display: flex;
			justify-content: space-between;
			margin: 5px 0 0;

			.attr-flags {
				svg {
					//display: block;
					padding: 1px 0 0 5px;
					font-size: 14px;
					vertical-align: text-bottom;
				}
			}
		}

		.attribute {
			//display: flex;
			//pointer-events: none;

			input {
				width: 100%;
				border: 1px solid rgba(0, 0, 0, 0.25);
				background-color: rgba(237, 237, 237, 0.15);
			}

			svg {
				font-size: 12px;
				flex-shrink: 0;
				margin: 2px 6px;
			}

			.text {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				display: block;
			}
		}

		.actions {
			position: absolute;
			top: 0;
			right: 0;
			margin: 5px;

			.edit-node {
				visibility: hidden;
			}

			.delete,
			.edit-node {
				transition: 0.4s all;
				border-radius: 50%;
				color: rgba(31, 31, 31, 0.75);
				display: block;

				&:hover {
					color: #ff2a2a;
					//color: #fff;
				}

				svg {
					vertical-align: middle;
					padding: 8px;
					cursor: pointer;
				}
			}
		}

		.has-shortcuts {
			transition: 0.4s all;
			position: absolute;
			top: 0;
			right: 0;
			margin: 8px;
			border-radius: 50%;
			color: #233c7c;
			font-size: 16px;
			cursor: help;

			svg {
				vertical-align: middle;
				padding: 4px;
			}
		}

		.upload:hover {
			background-color: var(--button-brand);
		}
	}
}
