@import "pallete.scss";

.livox-app .settings-screen {
  //width: 1024px;
  //margin: 10px auto;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 2fr 5fr;

  @include mobile {
    display: flex;
    width: auto;
    margin: 0;
    flex-grow: 1;
  }

  @include cellphone {
    flex-grow: 1;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  .menu-container {
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0;
    background-color: #d8e9d5;

    @include cellphone {
      flex-grow: 0;
    }
  }

  .menu {
    border-radius: 10px;
    padding: 1px 10px;
    max-width: 200px;
    margin-left: auto;

    @include mobile {
      background-color: transparent;
    }

    @include cellphone {
      display: none;
    }

    li {
      list-style-type: none;
      margin: 0;

      a {
        display: block;
        padding: 7px 10px;
        border-radius: 5px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.345);
        }

        &.active {
          background-color: rgba(255, 255, 255, 0.8);
        }
      }

      svg {
        vertical-align: text-bottom;
        margin-right: 5px;
      }
    }

    h3 {
      text-transform: uppercase;
      font-size: 12px;
      //font-weight: normal;
      margin: 15px 0 3px;
      color: var(--base-subtext);
      padding: 0 10px;
      letter-spacing: -0.2px;
    }
  }

  .main {
    flex-grow: 3;
    background-color: #fff;

    @include mobile {
      border-radius: 0;
      //background-color: #fff;
      background-color: rgba(255, 255, 255, 0.95);
    }

    .title {
      margin: 0 0 1em;
      padding: 0;
    }
  }

  .settings-main-menu {
    padding: 0 20px;

    h3 {
      text-transform: uppercase;
      font-size: 14px;
      color: #555;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      color: var(--base-brand);
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 10px;

      li {
        flex-basis: 0;
        flex-grow: 1;
        background-color: #fff;
        border: 1px solid var(--border);
        border-radius: 10px;
        text-align: center;

        svg {
          display: block;
          font-size: 40px;
          margin: 10px auto;
        }

        a {
          display: flex;
          flex-direction: column;
          padding: 0 10px 10px;

          span {
            flex-grow: 1;
            align-self: center;
          }
        }
      }
    }
  }

  .settings-section {
    margin: 0 1vw;
    padding: 0 15px;
    border-radius: 10px;
    max-width: 750px;

    @include mobile {
      margin: 10px 5px 0;
    }

    h3 {
      text-transform: uppercase;
      font-size: 13px;
      margin: 10px 0 5px;
      color: var(--base-subtext);
      padding: 0;
      letter-spacing: -0.2px;
    }

    //background-color: #fff;
    .app-setting {
      margin: 0 0 8px;
      padding: 0 0 8px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--border);
      }

      .stepper {
        display: flex;

        .buttons {
          display: flex;
          background-color: rgba(255, 255, 255, 0.872);
          border: 1px solid var(--button-brand);
        }
      }

      &.picker select {
        max-width: 170px;
        overflow: hidden;
        border-radius: 10px;
        padding: 0 5px;
        height: 27px;
        border: none;
        border: 1px solid var(--button-brand);
      }

      .description {
        margin: 0;
        font-size: 14px;
        color: var(--base-subtext);
        max-width: 500px;
      }

      .disabled-message {
        color: rgb(232, 51, 51);
      }

      .configure {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
          font-size: 18px;
        }
      }

      .value {
        margin: 0 7px;
        font-family: monospace;
        font-size: 18px;
        vertical-align: text-bottom;
        display: inline-block;
        text-align: center;
        font-weight: bold;
      }

      svg {
        margin: 0 3px;
        font-size: 20px;
        color: var(--button-brand);
        vertical-align: text-bottom;
        cursor: pointer;
      }

      .buttons {
        //background-color: rgba(255, 255, 255, 0.872);
        //border: 1px solid var(--button-brand);
        border-radius: 10px;
        padding: 0 4px;
      }

      .divider {
        border-left: 1px solid var(--button-brand);
        margin: 4px 0;
      }
    }
  }

  .item-preview {
    display: flex;
    flex-direction: column;
    margin: 0;
    flex-grow: 1;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    padding: 10px;
    background-color: #fff;
    margin: 10px auto;
    position: relative;

    .Media {
      margin: 0 auto;
    }

    .written-text {
      font-weight: bold;
      font-size: var(--card-font);
      text-align: center !important;
    }

    .images {
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0 auto 5px;
    }

    .image {
      max-height: none;
      max-width: none;
      min-height: auto;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      flex-grow: 1;
      -webkit-print-color-adjust: exact;
    }
  }

  // Sound
  .title-bar {
    display: flex;
    align-items: center;

    h2 {
      flex-grow: 1;
    }

    button,
    .btn button {
      background-color: transparent;
      color: var(--button-brand);
      flex-grow: 0;
      margin: 0;
    }
  }
}
