@import 'pallete.scss';

@include mobile {
	.changelog-mode {
		display: none;
	}
}

.sidebar {
	overflow: auto;
	min-width: 300px;
	max-width: 300px;
	transition: all 0.6s linear;
	position: relative;

	.close-mobile {
		position: absolute;
		right: 0;
		margin: 9px 15px;
		padding: 7px;
		font-size: 18px;
		display: none;

		@include mobile {
			display: block;
		}
	}

	&.closed {
		max-width: 1px;
	}

	&.open {
		max-width: 999px;
	}

	.header {
		font-weight: bold;
		padding: 15px 0 0;
		margin: 0 20px 0 20px;
		font-size: 16px;
	}

	.section {
		border-bottom: 1px solid var(--border);
		padding-bottom: 8px;
	}

	.content {
		padding: 10px 20px 3px;
		height: 100%;

		.field {
			margin: 0 0 10px;

			h4,
			label {
				margin: 0 0 6px;
				font-weight: normal;
				font-size: 16px;
				color: var(--base-subtext);
			}

			&.divide {
				display: flex;
				margin: 0;
				padding: 5px;
				cursor: pointer;
				align-items: center;
				transition: 0.2s all;
				background-color: var(--background-plain);
				color: #555555;				
				font-weight: 400;
				font-family: 'Proxima Nova','Open Sans',sans-serif;
				

				span {
					flex-grow: 1;
					display: block;
					cursor: pointer;
					margin: 0;
					margin-right: 12px;
				}

				&:hover {
					background-color: rgba(0, 0, 0, 0.05);
				}
			}
		}

		input[type='checkbox'] {
			display: block;
			width: 20px;
			height: 20px;
			cursor: pointer;
		}

		input, select {
			width: 100%;
			font-size: 14px;
			border: 1px solid var(--border);
			background-color: rgba(255, 255, 255, 0.08);
			color: var(--base-text);
			border-radius: 5px;
			outline: none;
			padding: 7px 10px;
			box-sizing: border-box;
		}

		.categories {
			display: flex;
			flex-wrap: wrap;

			li {
				list-style-type: none;
				width: 50%;
				margin: 5px 0 5px;
				padding: 5px 0;
				outline: 1px solid var(--background-accent);
			}
		}
	}

	.media {
		.header {
			justify-content: space-between;
			margin-bottom: 10px;

			button {
				background-color: transparent;
				color: red;
				padding: 0;
				flex-grow: 0;
				margin-top: -3px;
			}
		}

		video,
		audio {
			width: 100%;
			padding: 0 20px;
			box-sizing: border-box;
		}

		#mediaUploadForm {
			label {
				display: block;
				padding: 12px 20px 5px;
				color: var(--button-brand);
				cursor: pointer;
				user-select: none;
			}
		}
	}

	.color-picker {
		.content {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			.color {
				transition: 0.1s all;
				padding: 5px 6px 5px;
				border-radius: 50%;
				margin: 3px 0;
				cursor: pointer;
				border: 3px solid rgba(0, 0, 0, 0.08);

				&:hover,
				&.picked {
					border: 3px solid rgba(0, 0, 0, 0.25);
				}

				svg {
					opacity: 0;
					font-size: 16px;
					padding: 1px 0 0;
					color: #333;
				}

				&.picked {
					svg {
						opacity: 0.7;
					}
				}
			}
		}
		.remove {			
			background-color: var(--background-plain);
			color: var(--box-accent-negative);
			border-radius: 25px;
			padding: 8px;
			margin-top: 8px;
			cursor: pointer;
			-webkit-box-shadow: 9px 9px 33px -3px rgba(0,0,0,0.75);
			-moz-box-shadow: 9px 9px 33px -3px rgba(0,0,0,0.75);
			box-shadow: 9px 9px 33px -3px rgba(0,0,0,0.75);

			&:hover {
				background-color: var(--background-accent);
				color: #ff2a2a;
				cursor: pointer;
			}
		}		
	}

	// Gallery
	.radio-button {
		margin: 10px 20px 0;
		display: flex;

		.icons {
			align-self: center;
			flex-shrink: 1;
			width: 22px;

			svg {
				font-size: 22px;
				color: var(--base-subtext);
			}
		}

		label {
			flex-basis: 50%;
			transition: 0.1s all linear;
			flex-grow: 1;
			color: var(--base-subtext);
			cursor: pointer;
			position: relative;

			&:hover {
				background-color: rgba(0, 0, 0, 0.048);
			}

			span {
				transition: 0.1s all ease-in-out;
				padding: 7px;
				display: block;
			}
		}

		input {
			width: auto;
			display: none;
			margin: 0 5px;
			vertical-align: middle;

			&:checked + span {
				background-color: var(--border-brand);
				color: #fff;
				font-weight: bold;
			}
		}

		.image-attr {
			align-items: center;
			border: 1px solid var(--border-brand);

			> .image {
				width: 100%;
				height: 40px;
				background-size: contain;
				background-position: 50%;
				background-repeat: no-repeat;
				margin: 0 auto;
				border-radius: 0;
			}

			span {
				padding: 0;
				text-transform: capitalize;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.image-description {
				font-size: 14px;
				text-align: center;
				width: 100%;
			}

			.actions {
				display: inline-block;
				position: absolute;
				top: 1px;
				right: 1px;

				svg {
					padding: 4px;
					font-size: 15px;

					&:hover {
						background-color: #fff;
						color: var(--border-brand);
						border-radius: 50%;
					}
				}
			}
		}
	}

	.symbol-picker {
		///background-color: var(--background-accent);
		padding: 0;
		margin: 0 20px;

		.images {
			display: flex;
			flex-wrap: wrap;
			list-style-type: none;
			margin: 0;
			max-height: 160px;
			overflow: auto;

			> div {
				width: calc(25% - 6px);
				margin: 0 0 5px;
			}

			.item {
				position: relative;
				cursor: pointer;
				transition: all 0.1s linear;
				border-radius: 3px;
				background-color: var(--background-accent-store);
				padding: 2px 0;
				margin: 1px 3px;

				&.picked {
					background-color: var(--button-brand);
				}

				&:hover {
					.image {
						filter: blur(3px) brightness(0.8);
					}

					span {
						opacity: 1;
					}
				}
			}

			.image {
				height: 42px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				border-radius: 5px;
			}

			span {
				display: block;
				text-align: center;
				text-transform: capitalize;
				word-break: break-word;
				font-size: 8px;
				position: absolute;
				opacity: 0;
				font-weight: bold;
				color: #fff;
				text-shadow: 0 1px 5px #000;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.image-search {
			position: relative;
			padding: 7px 0 2px;

			form {
				position: absolute;
				top: 0;
				right: 0;
				margin: 8px 15px;
			}

			input {
				border-radius: 4px;
			}

			label {
				color: var(--base-subtext);
				cursor: pointer;
			}

			#uploadImage {
				display: none;
			}
		}
		
		.subtitle {
			margin: 0;
			font-size: 14px;
			color: var(--base-subtext);
		}
		.uploader {
			position: relative;
			padding: 10px 0;


			.amountImage {
				margin: 2px;
				font-size: 12px;
				color: var(--base-subtext);
			}

			form {
				position: absolute;
				top: 8px;
				right: 0;
				margin: 0 0;
				font-size: 15px;
			}

			label {
				color: var(--button-brand);
				cursor: pointer;
			}

			svg {
				vertical-align: text-bottom;
				margin: 0 3px;
			}

			#uploadImage {
				display: none;
			}
		}
	}

	.shortcuts {
		.shortcut {
			padding: 10px;
			display: flex;
			justify-content: space-between;
		}

		.shortcut-title {
			display: flex;
			margin: 20px 20px 0;
			font-size: 16px;

			.speech {
				font-size: 24px;
				color: red;
				cursor: pointer;

				&.recording {
					animation: recording 1s linear infinite;

					@keyframes recording {
						from { opacity: 1; }
						50% { opacity: 0; }
						to { opacity: 1; }
					}
				}

				svg {
					padding: 0 10px;
				}
			}
		}

		.setting {
			display: flex;
			justify-content: space-between;
			margin: 12px 0;
			padding: 0 20px;
	
			.stepper {
				.count {
					margin: 0 7px;
					font-family: monospace;
					font-size: 18px;
					vertical-align: text-bottom;
					width: 21px;
					display: inline-block;
					text-align: center;
				}
			}
		}

		p {
			margin: 5px 20px;
		}

		h5 {
			margin: 0;
		}

		.hero {
			text-align: center;
			margin: 20px 0 0;

			svg {
				border-radius: 50%;
				display: inline-block;
				padding: 5px;
				font-size: 100px;
				background-color: #aaa;
				color: #fff;
			}
		}

		.header {
			span {
				color: white;
				background-color: var(--base-text) 9f5;
				padding: 3px;
				margin: 0 10px;
				font-size: 16px;
				font-weight: bold;

				/* float: right; */
				display: block;
				border-radius: 5px;
			}
		}

		.remove-shortcut {
			cursor: pointer;

			svg {
				color: rgb(233, 98, 98);
			}
		}
	}
}
