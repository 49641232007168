@import "pallete.scss";
@import "~mobile-drag-drop/default.css";

.livox-studio {
	
	@media (orientation: landscape) {
		--horizontal-items-ammount: var(--collumns-amount);
		--vertical-items-ammount: var(--rows-amount);
	}

	@media (orientation: portrait) {
		--horizontal-items-ammount: var(--rows-amount);
		--vertical-items-ammount: var(--collumns-amount);
	}

	background-color: var(--background-box);

	@include tablet {
		// TABLET ONLY
		.sidebar {
			bottom: 42px;
			box-shadow: 0 -4px 6px rgba(50, 50, 93, 0.21), 0 -1px 3px rgba(41, 41, 41, 0.48);

			.close-mobile {
				margin: 5px 15px;
			}
		}

		.editor {
			display: grid;
			grid-template-columns: 1fr 2fr;
			grid-template-rows: 1fr auto;

			.media {
				grid-column-start: 1;
				grid-row-start: 2;
			}

			.images {
				max-height: 80px;
			}

			.gallery {
				.gallery-container {
					display: grid;
					grid-template-columns: 1fr 3fr;
				}

				.radio-button {
					display: block;
					margin: 5px;

					label {
						border: 0;
					}

					.icons {
						display: flex;
						width: auto;
						margin: 5px 0;
						justify-content: center;

						.arrow {
							transform: rotate(90deg);
						}

						svg {
							font-size: 20px;
						}
					}
				}
			}
				

		}


	}

	@include mobile {
		display: flex;
		flex-direction: column;
		height: 100vh;

		.hide-mobile {
			display: none;
		}

		.header.container {
			.board-controls {
				.image {
					width: 30px;
					height: 25px;
					margin: 0;
				}

				.title {
					font-size: 14px;
					width: auto;
				}
			}

			.hide-mobile {
				display: none;
			}

			.studio-controls .button-play {
				color: var(--button-brand);
				background-color: transparent;
				padding: 0 0 3px;

				span {
					display: none;
				}
			}
		}

		.sidebar.null-mode {
			min-width: auto;
			max-width: 0;
		}

		.sidebar {
			min-width: 100%;
			position: absolute;
		}

		.divider {
			flex-grow: 1;

			.tree {
				height: auto !important;
			}
		}

		.path {
			.point {
				//flex: 1 1 0;
				margin: 0 !important;
				font-size: 14px;
				border-radius: 3px;
				padding: 4px !important;
				flex-shrink: 1;
			}

			.root-point,
			svg {
				flex-shrink: 0;
			}
		}

		.folder-actions {
			display: none;
		}

		&.edit {
			.item li {
				padding: 10px 10px 0 !important;
				margin: 5px auto;
				width: calc(100% - 30px);

				.written-text {
					font-size: 14px !important;
				}

				.spoken-text {
					font-size: 12px;

					svg {
						font-size: 11px !important;
					}
				}

				.images {
					margin: 0 auto 7px !important;
				}

				.image {
					max-height: 50px !important;
					min-height: 50px !important;
				}

				.actions {
					display: flex;
					margin: 0 !important;

					span {
						flex-shrink: 1;

						svg {
							padding: 5px 5px 7px !important;
							font-size: 12px;
						}
					}
				}

				.edit-node {
					visibility: visible !important;
				}
			}
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		padding: 10px 20px 5px;

		.board-controls {
			display: flex;

			.image {
				width: 62px;
				height: 62px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				border-radius: 10px;
				margin: 0;
			}

			.title {
				margin: 0 10px;
				font-size: 20px;
				border: none;
				padding: 5px;
				width: 100%;
				border: 1px solid transparent;
				background-color: var(--background-box);
				color: var(--base-text);

				&:hover {
					border: 1px solid var(--border);
				}
			}

			svg {
				vertical-align: text-bottom;
				margin: 0;
			}
		}

		.studio-controls {
			align-self: center;
			display: flex;
			align-items: center;

			svg {
				vertical-align: text-bottom;
				margin: 0 5px 0 0;
			}

			.button-play {
				//border: 1px solid var(--border);
				border-radius: 3px;
				padding: 8px 15px;
				cursor: pointer;
				background-color: var(--button-brand);
				color: #fff;
			}

			.button-print {
				margin: 0 20px;
				cursor: pointer;
			}

			.settings {
				font-size: 20px;
				cursor: pointer;
			}
		}
	}

	// TODO move to its own file

	.toolbar {
		display: flex;

		@include mobile {
			button {
				margin: 0 !important;
			}
		}

		button {
			//border: 1px solid var(--border);
			background-color: var(--background-box);
			color: var(--base-text);
			font-weight: normal;
			font-size: 14px;
			padding: 6px 7px;
			margin: 0 8px;
			cursor: pointer;
			display: block;
			user-select: none;
			transition: all 0.1s linear;
			border-radius: 7px;

			svg {
				font-size: 16px;
			}

			&:hover {
				background-color: rgba(0, 0, 0, 0.125);
			}

			&:disabled {
				color: var(--disabled);
			}
		}

		.clipboard {
			display: flex;
			gap: 5px;

			button {
				margin: 0;
			}
		}

		svg {
			vertical-align: text-bottom;
		}

		label.uploading {
			svg {
				animation: spin 1s linear infinite;
			}

			@keyframes spin {
				100% {
					transform: rotate(360deg);
				}
			}
		}

		.save-status {
			padding: 6px;
			margin: 0 10px;
		}

		svg {
			vertical-align: bottom;
		}
	}

	.folder-toolbar {
		display: flex;
		padding: 0 12px;
		justify-content: space-between;

		.folder-actions {
			align-self: center;
			font-size: 26px;
			color: var(--base-text);
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			
			.shortcut, .ai-recommendations{
				border-radius: 50%;
				padding: 5px 5px;
				margin: 7px 10px 0;
				border: 1px solid transparent;
				&.active {
					border-color: var(--button-brand) !important;
				}
				&.has-items {
					background-color: var(--background-box);
					color: var(--button-brand);
				}
			}

			svg {
				vertical-align: middle;
			}

			&:hover {
				transition: 0.2s linear all;
				//border-color: var(--blue);
				//color: var(--blue);
			}
		}
	}

	.path {
		padding: 10px 0 3px;
		display: flex;
		overflow: auto;

		svg {
			font-size: 24px;
			align-self: center;
			color: var(--base-subtext);
		}

		.point {
			border-radius: 10px;
			display: flex;
			align-items: center;
			padding: 5px 10px;
			margin: 3px 0;
			cursor: pointer;
			user-select: none;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #333;
			border: 1px solid rgba(0, 0, 0, 0.125);

			.Media {
				width: 25px;
				height: 20px;
				margin: 0 5px 0 0;
				border-radius: 4px;
			}

			&:hover {
				background-color: var(--background-accent);
			}

			&.over {
				box-shadow: inset 0 0 18px -4px rgba(0, 0, 0, 0.5);
			}
		}
	}

	.divider {
		display: flex;
		border-top: 1px solid var(--border);
		height: calc(100vh - 81px);		// height: calc(100vh - 79px);
		background-color: var(--background-accent-store);

		.tree {
			flex-grow: 1;
			overflow: auto;
			border-right: 1px solid var(--border);
			height: stretch;
			padding: 0 10px;

			.item-expanded {
				li:not(.expanded) {
					opacity: 0;
					pointer-events: none;
				}
			}
		}

		.sidebar {
			background-color: var(--background-box);
		}
	}

	.states {
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-items: center;
		gap: 15px;

		.state-card {
			display: flex;
			flex-direction: column;  
			background-color: #f1f1f1;
			position: relative;
			text-align: center;			
			
			img {
				padding-block-start: 5px;
				height: 64px;
			}			

			align-items: center;
			border-radius: 10px;
			width: 120px;
			height: 120px;
			cursor: pointer;
			transition: 0.3s;			
	
			&:hover{
				background-color: rgb(207, 207, 207);
			}
			-webkit-box-shadow: 4px 4px 17px 0px rgba(181,181,181,1);
			-moz-box-shadow: 4px 4px 17px 0px rgba(181,181,181,1);
			box-shadow: 4px 4px 17px 0px rgba(181,181,181,1);
	
			span {
				font-size: 20px;
				color: #333;
			}

			.written-text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 90%;
			}
	
			&.editing {
				//border-width: 3px;
				//padding: 20px;
				border-color: #288aec;
				//outline: 5px solid #288aec;
				box-shadow: 0 0 8px 1px rgb(42, 108, 243);
			}
			.spanRemove {
				background-color: red;
			}		

			.remove {			
				position: absolute;
				background-color: var(--background-plain);
				color: var(--box-accent-negative);
				padding: 3px;
				top: 6px;
				right: 4px;
				border-radius: 25px;
				cursor: pointer;
				-webkit-box-shadow: 9px 9px 33px -3px rgba(0,0,0,0.75);
				-moz-box-shadow: 9px 9px 33px -3px rgba(0,0,0,0.75);
				box-shadow: 9px 9px 33px -3px rgba(0,0,0,0.75);

				&:hover {
					background-color: var(--background-accent);
					color: #ff2a2a;
					cursor: pointer;
				}
			}
		}				
	}
}
