@import 'pallete.scss';

.login-background {
	background-color: var(--base-brand);
	background: linear-gradient(141deg, #0e7a12 0%, #39911b 50%, #6eac22 100%);
	min-height: 100vh;
	min-width: 100vw;
	overflow: auto;
}

.login-modal {
	z-index: 10;
	background-color: #fff;
	max-width: 400px;
	margin: 60px auto 30px;
	padding: 20px 30px 5px;
	box-shadow: 1px 3px 5px 0 #2323238f;

	label span {
		color: #333;
	}

	input,
	legend {
		background-color: #fff;
		color: #333;
	}

	img {
		width: 100%;
		margin: 0 auto;
		display: block;
		padding: 0 40px 15px;
		box-sizing: border-box;
	}

	a {
		text-align: center;
		color: inherit;
		text-decoration: inherit;
		color: var(--base-brand);
		display: block;
		margin: 5px;
	}

	a:hover {
		color: var(--dark-brand);
	}

	.version{
		color:var(--base-brand);
		display: flex;
		justify-content: center;
		font-size: 14px;
	}
}

.login-form {
	button {
		width: 100%;
		margin: 0 10px;
		font-size: 18px;
	}

	.cancel {
		display: none;
	}
}

.auth a {
	padding: 0 0 12px;
}

.store-shortcut {
	padding: 20px 30px;

	svg {
		font-size: 40px;
	}

	span {
		font-size: 24px;
		margin: 0 20px;
	}
}
