@import "pallete.scss";

.search-bar {
	display: flex;
	border-bottom: 1px solid var(--button-brand);
	vertical-align: bottom;

	.icon {
		align-self: flex-end;
		padding: 7px;
		color: var(--button-brand);
	}

	input {
		align-self: flex-end;
		display: inline-block;
		font-size: 16px;
		padding: 7px;
		box-sizing: border-box;
		border: none;
		background-color: transparent;
		color: var(--base-text);
	}
}
