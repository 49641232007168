.intellibar {
	display: flex;
	flex-direction: row;
	background-color: #fff;
	justify-content: space-between;
	width: 100%;
	height: 20vh;

			/* ===== Scrollbar CSS ===== */
  /* Firefox */
	scrollbar-width: auto;
	scrollbar-color: var(--base-text) var(--background-plain);

/* Chrome, Edge, and Safari */
::-webkit-scrollbar {
	width: 16px;
}

::-webkit-scrollbar-track {
	background: var(--background-plain);
}

::-webkit-scrollbar-thumb {
	background-color: var(--base-text);
	border-radius: 10px;
	border: 3px solid var(--background-plain);
}

	@media print {
		display: none;
	}

	.intellibar-items {
		display: flex;
		list-style: none;
		gap: 10px;
		overflow-x: auto;
		height: 165px;
		margin-left: -30px;
		

		.item {
			width: 150px;
			height: 150px;
			text-align: center;
			font-weight: 600;
			color: #333;			

			.written-text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 90%;
			}
		}

		.image {
			min-height: 70px !important;
			max-height: none;
			max-width: none;
			min-height: auto;
			flex-grow: 1;
			width: 100%;
			height: 100%;
			margin: 0 auto;						
		}
	}

	.intellibar-controls {
		display: flex;
		align-items: center;

		button {
			font-size: 80px;
			border-radius: 10px;
			color: #fff;
			margin: 0 5px;
		}

		.delete {
			background-color: var(--base-text);
			color: var(--background-plain);

			&:hover{
				background-color: #9e2523;
			}

			&:disabled {
				background-color: var(--disabled);
			}
		}

		.play {
			background-color: var(--base-text);
			color: var(--background-plain);

			&:disabled {
				background-color: var(--disabled);
			}
		}
	}
}
