.livox-app .settings-screen {
	.keywords.app-setting {
		button {
			background-color: transparent;
			color: var(--button-brand);
		}

		ul,
		li {
			margin: 0;
			padding: 0;
			list-style-type: none;

			.keyword {
				text-transform: capitalize;
				margin: 0 5px;
			}

			button {
				margin: 0;
				padding: 3px 0;

				svg {
					color: red;
					margin: 0;
				}
			}
		}

		ul {
			margin: 5px 0 0;
		}
	}
}
