@import 'pallete.scss';

// Users
.users-setting {
	.users {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		gap: 20px;
		margin: 10px 0 0;
	}

	a:hover {
		background-color: #fff;
		transform: scale(1.1);
		transition: 0.12s all;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.125);
		border-radius: 10px;
	}

	.title-bar {
		display: flex;

		h2 {
			flex-grow: 1;
		}
	}

	.user-name {
		text-align: center;
		display: block;
		font-size: 1em;
		margin: 10px 0 0;
	}

	.user-info {
		img {
			display: block;
			margin: 0 auto 20px;
		}

		.delete {
			margin: 10px auto;
			display: block;
			background-color: transparent;
			color: #fb161b;
		}
	}

	.user-table {
		max-width: 400px;
		margin: 10px auto 0;
		display: grid;
		grid-template-columns: auto 1fr;
		gap: 10px 10px;
	}

	.user-card {
		font-weight: bold;
		padding: 0.5em;
		border-radius: 10px;

		.user-avatar img {
			margin: 0 auto;
			display: block;
		}

		svg {
			width: 125px;
			height: 125px;
			object-fit: contain;
			border-radius: 50%;
			background-color: #fff;
			display: block;
			margin: 0 auto;
			color: var(--border-brand);
		}

		img,
		.image-placeholder {
			margin: 0 auto;
		}
	}
}
