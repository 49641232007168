.livox-app .create-user {
	max-width: 750px;

	.user-info {
		padding: 20px 0;

		img,
		.image-placeholder {
			margin: 0 auto 20px !important;
			display: block;
		}

		input,
		select {
			display: block;
			border: none;
			border-bottom: 1px solid var(--border);
			padding: 5px;
			margin: 0 auto 10px;
			font-size: 18px;
			outline: none;
			text-align: center;
		}

		label {
			color: var(--base-subtext);
			text-align: center;
			display: block;
			font-size: 14px;
			margin: 5px;
		}
	}

	.voice-picker {
		display: flex;
		justify-content: center;

		> * {
			margin: 0 5px;
		}

		select {
			margin: 0;
			height: 2.2em;
		}

		#voice {
			width: 187px;
		}

		.voice-test {
			//align-self: center;
			padding: 0 6px;
			flex-grow: 0;
			//border: 1px solid var(--border);
			background-color: var(--button-brand);
			color: #fff;
			border-radius: 100%;
			display: flex;
			align-items: center;
			cursor: pointer;

			svg {
				font-size: 22px;
				vertical-align: text-bottom;
			}
		}
	}

	.radio-question {
		label {
			display: block;
		}
	}

	.create {
		display: block;
		margin: 0 auto;
	}
}
