@import 'pallete.scss';

.store-categories-old {
	display: flex;
	max-width: 1000px;
	margin: 0 auto;

	@include mobile {
		display: block;
	}

	justify-content: space-evenly;
	color: #fff;

	.category {
		flex-basis: 33%;
		margin: 10px;
		background-color: #066b0a;
		padding: 5px;
		border-radius: 15px;

		.title {
			margin: 0 5px;
			padding: 10px 0 10px;
			text-align: center;
			font-size: 26px;
			font-weight: normal;
			flex-grow: 1;
		}

		.flex {
			display: flex;
			margin: 7px 0 5px;
		}

		.content {
			margin: 0 5px;
		}

		img {
			width: 52px;
			margin-inline-start: 15px;
		}

		.subcat {
			display: flex;
			flex-wrap: wrap;

			li {
				flex-basis: 33%;
				list-style: none;
				cursor: pointer;
				padding: 10px;
				flex-grow: 1;
				border-radius: 20px;
				transition: 0.12s all linear;

				&:hover {
					background-color: rgba(0, 0, 0, 0.15);
				}
			}
		}
	}
}
