.livox-app {
	#navbar {
		max-height: 80px;
		transition: 0.2s all;

		&.collapsed {
			max-height: 0;
			overflow: hidden;
		}
	}

	.play-controls {
		flex-grow: 1;
		justify-content: space-between;

		.settings {
			padding: 0;
		}
	}
}
