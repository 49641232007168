@import "pallete.scss";

.sponsor-dashboard {
	color: #333;
	.dashboard-section {
		padding: 20px;
		box-shadow: 1px 3px 5px 0 #2323238f;

		max-width: 100ch;
		background-color: white;
		margin: 3vh auto;

		> .header{
			display: flex;
			h2 {
				margin: 0 5px 20px;
			}
			svg{
				vertical-align: text-bottom;
				font-size: 1.5em;
				cursor: pointer;
				color: var(--button-brand);
			}
	
		}
	}

	.contact-info{
		display: flex;
		justify-content: space-evenly;
		margin: 0 0 10px;
		svg{
			margin: 0 1px 0 0;
		}
	}

	.chart-loader {
		height: 300px;
		width: calc(100% - 70px);
		background-color: rgb(215, 215, 215);
		margin: 10px 20px;
	}

	.graphs {
		display: grid;
		grid-template-columns: 6fr 3fr;

		@include mobile {
			display: block;
		}
	}

	.dashboard-card {
		border: 1px solid #DEE1E5;
		border-width: 1px 0 1px;
		padding: 0;
		//background-color: var(--background-box);

		.card-head {
			display: none;
		}
	}

	.dash-card {
		display: flex;
		justify-content: space-between;

		.rate {
			margin: 5px 0;
			font-weight: bold;
		}
	}

	.card-section {
		display: flex;

		h3 {
			margin: 0 0;
			font-size: 16px;
			font-weight: normal;
		}

		> * {
			flex-grow: 1;
			flex-basis: 0;
			padding: 10px 15px;
		}

		.vl {
			padding: 0 1px 0 0;
			background-color: var(--border);
			margin: 5px 0;
			flex-grow: 0;
		}

		> div p {
			font-weight: bold;
			font-size: 25px;
			margin: 5px 0 0;
		}
	}
}
