@import 'pallete.scss';

.store-navbar {
	background-color: var(--background-box);
	border-bottom: 1px solid var(--border);
	z-index: 99;
	padding: 7px;

	.mobile-expand {
		display: none;
	}

	.container {
		max-width: 1050px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
	}

	.logo {
		img {
			height: 40px;
			transition: 0.25s;
		}
	}

	.main-menu {
		margin: 0;
		list-style-type: none;
		display: flex;
		align-items: center;

		li {
			margin: 0 5px;

			button {
				background-color: transparent;
				color: var(--button-brand);
				border-radius: 20px;
				padding: 4px 3px;
				font-size: 23px;
				margin: 0 10px;
				outline: none;

				svg {
					padding-top: 2px;
				}

				&:hover {
					background-color: var(--button-brand);
					color: #fff;
				}
			}
		}
	}

	.submenu {
		ul {
			transition: 0.2s linear all;
			position: absolute;
			right: -50px;
			display: block;
			background-color: var(--background-box);
			padding: 0;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
			overflow: hidden;
			border-radius: 12px;
			opacity: 1;
			z-index: 99;

			@include mobile {
				width: 100%;
				left: 0;
			}
		}

		li {
			padding: 10px;
			margin: 0;

			&.color-toggler {
				padding: 0;

				div {
					padding: 10px;
				}
			}

			button {
				margin: 0;
				padding: 0;
			}

			svg {
				vertical-align: bottom;
				margin-inline-end: 5px;
			}

			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
		}
	}

	.sections.hide {
		display: none;
		opacity: 0;
	}

	.main-link {
		color: var(--button-brand);
		padding: 10px 10px;
		font-weight: bold;
		border-radius: 20px;
		font-size: 20px;
		transition: all 0.16s ease-in-out;

		&:hover {
			background-color: var(--button-brand);
			color: #fff;
		}
	}

	.user-picture {
		img {
			transition: all 0.16s ease-in-out;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background-color: #ccc;
			cursor: pointer;

			&:hover {
				background-color: #aaa;
			}
		}
	}

	.review {
		position: relative;
	}

	.dot {
		width: 7px;
		height: 7px;
		border-radius: 50%;
		background-color: orange;
		position: absolute;
		right: 0;
		bottom: -1px;
	}
}
