.ai-recommend {
  .search {
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 10px 0;
  }
  .explanation-box {
    margin: 20px 10px 0;
    padding: 10px;
    h3 {
      text-align: center;
    }
    p {
      font-size: 16px;
    }
  }
  .error{
    background-color: rgb(200,0,0,50%);
  }
  .content {
    padding: 0 5px;
  }
  .proposed-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 10px;
  }
  .card-proposal {
    text-align: center;
    padding: 5px;
    border: 2px solid var(--border);
    border-radius: 5px;
    display: grid;
    cursor: pointer;

    &:hover {
      background-color: var(--hover-accent);
    }

    .image {
      font-size: 40px;
    }
    .text {
      text-overflow: ellipsis;
      flex-grow: 1;
      align-self: center;
    }
  }
  .loading {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 10px;
    padding: 0 5px;

    .shimmer {
      background: #f6f7f8;
      background: rgb(246 247 248 / 40%);
      background-image: linear-gradient(
        to right,
        rgb(246 247 248 / 30%) 0%,
        rgb(237 238 241 / 30%) 20%,
        rgb(246 247 248 / 30%) 40%,
        rgb(246 247 248 / 30%) 100%
      );
      background-repeat: no-repeat;
      background-size: 376px 114px;
      display: inline-block;
      position: relative;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeholderShimmer;
      animation-timing-function: linear;
    }
    .box {
      height: 68px;
      text-align: center;
      padding: 7px;
      border: 2px solid var(--border);
      border-radius: 5px;
      display: grid;
    }
    .round {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin: 0 auto;
    }
    .block{
        border-radius: 5px;
        margin: 5px 10px 0;
    }
  }
  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
}
