@import "pallete.scss";

.back-link {
  color: var(--dark-brand);

  svg {
    vertical-align: top;
    margin: 0 2px 0 0;
  }
}

.share-modal {
  h3 {
    text-align: center;
    font-weight: normal;
    font-size: 24px;
  }

  .share-options {
    display: flex;
    flex-wrap: wrap;

    > div,
    > a {
      width: 40%;
      flex-grow: 1;
      padding: 10px 7px;
      margin: 6px 10px;
      border-radius: 10px;
      font-size: 20px;
      color: #fff;
      cursor: pointer;

      svg {
        vertical-align: bottom;
        padding: 0 10px;
        font-size: 20px;
      }
    }
  }
}

.store-view {
  max-width: 1200px;
  margin: 20px auto 0;
  display: flex;

  @include mobile {
    display: block;
  }

  .content {
    flex-grow: 1;
    max-width: 870px;
    margin: 0 auto;

    @include mobile {
      padding-left: 0;
      padding: 0 20px;
    }

    .header {
      display: flex;
      margin: 0 0 5px;

      @include mobile {
        flex-wrap: wrap;
      }

      > .title {
        margin: 5px 0;
        font-weight: normal;
      }

      .Media {
        width: 180px;
        height: 180px;
        border-radius: 20px;
        border: 1px solid var(--border);
        display: block;
        background-color: #fff;

        @include mobile {
          width: 110px;
          height: 110px;
        }
      }

      .info {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        flex-grow: 1;
        margin-inline-start: 30px;

        @include mobile {
          margin: 5px 10px;

          .title {
            font-size: 20px;
          }
        }

        .metadata {
          .title {
            margin: 0 0 5px;
            //font-weight: normal;
          }
        }

        .badges {
          margin: 0 -7px;
        }
      }

      .author {
        display: block;
        font-size: 17px;
        margin: 0 0 3px;
        color: var(--dark-brand);
      }

      .language {
        display: none;
        text-align: end;
        font-size: 16px;
        margin: 1px;
        margin-top: -28px;

        img {
          height: 27px;
        }
      }

      .features {
        width: 100%;
        font-size: 14px;
      }
    }

    .actions {
      display: flex;
      margin: 10px 0 0;
      justify-content: space-evenly;
      flex-grow: 1;
      border-top: 1px solid var(--border);
      flex-wrap: wrap;

      .action button,
      .action > div > button {
        background-color: transparent;
        color: var(--base-subtext);
      }

      .download button {
        color: var(--dark-brand);
      }
    }
  }

  .evaluation {
    padding: 0 20px;
  }

  .content-rating {
    font-family: BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
      Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;

    span {
      font-size: 25px;
      font-weight: 500;
    }

    svg {
      &.filled {
        color: #ffd00f;
        fill: #ffd00d;
      }

      color: rgb(167, 167, 167);
      fill: rgb(196, 192, 192);
      font-size: 28px;
      vertical-align: text-bottom;
    }
  }

  .evaluate {
    h4 {
      font-size: 16px;
      font-weight: normal;
      color: var(--base-subtext);
      margin: 10px 0 4px;
      text-align: center;
    }

    .stars {
      display: flex;
      text-align: center;
      justify-content: center;
      margin: 5px 0 0;
    }

    svg {
      font-size: 20px;
      cursor: pointer;
      color: var(--base-subtext);
      margin: 0 2px;

      &.candidate {
        fill: rgb(253, 253, 124);
      }

      &.filled {
        color: #ffd00f;
        fill: #ffd00d;
      }
    }
  }

  .description {
    font-size: 19px;
    line-height: 1.35;
    white-space: pre-line;
    margin: 30px 0 0;
    color: var(--base-subtext);
    padding: 0 10px;
  }

  $device-height: 520px;
  $bar-height: 3px;

  .device {
    padding: 20px 20px 32px;
    border-radius: 22px;
    background: -webkit-linear-gradient(
      315deg,
      #555,
      #474747 25%,
      #2c2c2c 50%,
      #2c2c2c 0,
      #000 75%,
      #131313
    );
    background: linear-gradient(
      135deg,
      #555,
      #474747 25%,
      #2c2c2c 50%,
      #2c2c2c 0,
      #222 75%,
      #1d1d1d
    );
    border: 1px solid #949494;
    height: $device-height;
    margin: 20px auto;

    @include mobile {
      padding: 10px 7px 30px;
      margin: 20px -15px;
    }

    .frame {
      height: 100%;
    }
  }

  // Studio overrides
  .livox-studio {
    height: 100% !important;
    //background-color: #000;
    position: relative;

    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-track {
      background: #000;
    }

    ::-webkit-scrollbar-thumb {
      background: #dedede;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #b1b1b1;
    }

    .header.container {
      position: absolute;
      top: 101%;
      z-index: 10;
      background-color: transparent;
      color: #fff;
      margin: 0;
      width: 100%;
      padding: 0;
    }

    .board-controls {
      padding: 0;
      margin: 0 auto;
    }

    .toolbar {
      margin: 0 0; //auto;
      padding: 0 0;

      > * {
        background-color: transparent;

        &.disabled {
          color: #999;
        }
      }
    }

    .sidebar {
      display: block !important;
      max-width: 330px;
      min-width: 0;
      background-color: #000;
      color: #fafafa;

      @include mobile {
        //display: none !important;
        width: 100%;
      }

      .section {
        border-bottom: none;
      }
    }

    .divider {
      border: none;
      display: flex;
      height: $device-height; // - $bar-height;
      //padding-top: $bar-height;
      background-color: #000;
    }

    .attribute.written-text {
      text-align: center !important;
      display: block !important;
    }

    .tree {
      border: none !important;
      padding: 10px;
    }

    .items li {
      border: none !important;
      border-radius: 20px;
    }

    .store-hide {
      display: none;
    }
  }

  .feature-badge {
    border: 1px solid;
    border-radius: 5px;
    padding: 5px 7px;
    margin: 0 10px 0 0;
    display: inline-block;
    background-color: var(--background-box);
    font-weight: bold;

    svg {
      vertical-align: top;
      margin-inline-end: 2px;
    }

    &.shortcut {
      color: #233c7c;
      border-color: #233c7c;
    }

    &.book-mode {
      color: #b10505;
      border-color: #b80505;
    }
  }
}
