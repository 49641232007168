@import 'pallete.scss';

.store-home {
	display: flex;

	.main {
		flex-grow: 1;
	}

	@include mobile {
		display: block;
	}

	.main {
		flex-grow: 1;
	}
}

.categories {
	padding-inline-end: 20px;

	.store-categories {
		.flex {
			display: flex;

			svg {
				color: #777;
				padding-inline-end: 7px;
			}
		}

		.content {
			li {
				list-style-type: none;
				color: var(--base-subtext);
				font-size: 16px;
				padding: 0 25px 15px;
			}
		}

		.title {
			font-size: 18px;
			font-weight: normal;
			padding: 0 0 10px;
		}
	}
}

.section h2 {
	padding: 0 10px;
	margin: 22px 0 10px;
	font-weight: normal;
}
