@import 'pallete.scss';

.my-boards {
	.title {
		width: 160px;
		text-align: start;
		padding-bottom: 0;
	}

	.date {
		color: var(--base-subtext);
	}

	.header {
		display: flex;

		@include mobile {
			display: block;
		}

		justify-content: space-between;

		.actions {
			align-self: center;
		}
	}

	.board {
		display: block;

		@include mobile {
			.content {
				display: flex;
			}
		}
	}

	.tutorial {
		background-color: var(--background-accent);
		border: 1px solid var(--border);
		padding: 10px 15px;

		h2 {
			text-align: center;
		}

		.steps {
			display: flex;

			@include mobile {
				display: block;
			}

			.step {
				padding: 0 25px 20px;
				flex-basis: 33%;

				img {
					max-width: 75%;
					max-height: 150px;
					margin: 0 auto;
					display: block;
				}
			}
		}
	}
}
