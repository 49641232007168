.view {
	.changelog {
		display: none;
	}
}

.changelog {
	padding: 0 20px 20px;
	font-size: 14px;

	.header {
		margin: 0 0 10px;
	}

	img {
		max-width: 200px;
		display: block;
		margin: 10px auto;
	}

	kbd {
		font-family: Lato, Helvetica Neue, Helvetica, sans-serif;
		background-color: var(--background-modal);
		border: 1px solid rgba(63, 63, 63, 0.25);
		border-radius: 3px;
		box-shadow: 0 1px 0 rgba(63, 63, 63, 0.25);
		display: inline-block;
		font-size: 0.8em;
		margin: 0 0.1em;
		padding: 0.1em 0.6em;
		white-space: nowrap;
	}

	ul {
		margin: 0;
		padding: 0 5px;
	}

	p,
	li {
		margin: 0 0 9px;
	}
}
