@import 'pallete.scss';

.livox-app {
	a,
	button {
		-webkit-touch-callout: none;
	}

	* {
		text-size-adjust: none;
		-webkit-tap-highlight-color: transparent;
		outline: none;
		touch-action: pan-y;
	}

	display: grid;
	width: 100vw;
	height: var(--innerHeight);
	background: linear-gradient(141deg, #0e7a12, #39911b 50%, #6eac22);
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;

	.user-picker {
		color: #fff;
		margin: 10vh auto;
		text-align: center;
		cursor: pointer;

		h1 {
			margin: 10vh 0;
			font-size: 40px;
		}

		.users {
			display: flex;
			flex-wrap: wrap;

			.user {
				margin: 0 25px;
				background-color: transparent;
				border-radius: 10px;

				&:hover {
					background-color: rgba(255, 255, 255, 0.8);
					color: var(--base-brand);
				}
			}
		}

		.user-name {
			display: block;
			margin: 10px 0;
		}
	}

	.user-avatar {
		img {
			object-fit: contain;
			border-radius: 50%;
		}

		.image-placeholder {
			width: 100px;
			height: 100px;
			border-radius: 50%;
			background: linear-gradient(0deg, #0e7a12, #39911b 50%, #6eac22);
			display: block;
			font-weight: bold;
			margin: 0 auto;

			.placeholder-text {
				text-transform: uppercase;
				color: #fff;
				font-size: 30px;
				display: block;
				text-align: center;
				padding: 32px 0;
			}
		}
	}

	// TODO move to its own filess
	@include mobile {
		grid-template-rows: 1fr auto;
	}

	@include cellphone {
		.controls ul a span,
		.current-user > svg {
			display: none;
		}

		.user-switcher {
			width: 20%;

			.current-user {
				justify-content: center;

				img {
					height: 25px !important;
				}
			}
		}

		.nav-bar {
			// TODO IMPLEMENT SAFE AREAS env(safe-area-inset-bottom)
		}
	}

	.main {
		overflow: auto;
		display: flex;
		flex-direction: column;
	}

	// Overrides
	.livox-studio {
		&.edit {
			background-color: var(--background-plain);
		}

		.header.container {
			padding: 10px 20px 7px;
		}

		input.title,
		.media-container {
			display: none;
		}
	}

	.store-navbar {
		display: none;
	}

	.livox-studio.edit .divider {
		height: calc(100vh - 109px);
	}
}
