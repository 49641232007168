.livox-studio {
  &.view,
  &.store {
    height: 100vh;
    background-color: #000;

    .header {
      background-color: #18191a;
    }

    .view-hide {
      display: none !important;
    }

    .divider {
      height: auto;
      background-color: #000;
    }

    .studio-controls {
      color: #fff;
    }

    .item svg {
      display: none;
    }

    .toolbar span,
    a,
    label {
      color: #fff;
      background-color: #18191a;
    }

    .divider {
      border: none;
    }

    .items li {
      border: none;

      .written-text {
        font-weight: bold;
        font-size: var(--card-font);
        margin: 0;
        text-align: center !important;

        .text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          text-align: center !important;
        }
      }
    }

    .player {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      background-color: #000;

      video {
        width: 100%;
        height: 100%;
      }
    }
  }

  // Grid setup
  &.view {
    display: flex;
    flex-direction: column;
    height: 100vh;

    @media print {
      .header {
        display: none;
      }

      .divider {
        background-color: #fff;
      }

      .item li {
        outline: 1px solid black;
      }
      

      .sidebar {
        display: none;
      }
    }

    .divider {
      height: 100%;
      flex-grow: 1;

      .sidebar {
        min-width: auto;
        background-color: #18191a;
        color: #fff;

        &.settings-mode {
          min-width: 280px;
        }
      }

      .tree {
        display: grid;
        height: auto;
        padding: 10px 10px 20px;
        border: none;

        // Normal paging mode (item)
        .items {
          display: grid;
          gap: 1.1vw;
          grid-template-columns: repeat(
            var(--horizontal-items-ammount),
            minmax(0, 1fr)
          );
          grid-template-rows: repeat(
            var(--vertical-items-ammount),
            minmax(0, 1fr)
          );
        }

        .item.Back {
          grid-row-start: var(--vertical-items-ammount);
          grid-column-start: 1;
					@media print {
						display: var(--navigation-card);
					}          
        }

        .item.Next {
          grid-row-start: var(--vertical-items-ammount);
          grid-column-start: var(--horizontal-items-ammount);
					@media print {
						display: var(--navigation-card);
					}          
        }

        // Fixed page mode
        &.fixed-page-mode .items {
          grid-template-rows: repeat(
              var(--vertical-items-ammount),
              minmax(0, 1fr)
            ) 1fr;

          .item.Back {
            grid-row-start: calc(var(--vertical-items-ammount) + 1);
          }

          .item.Next {
            grid-row-start: calc(var(--vertical-items-ammount) + 1);
          }
        }

        &.swipe-page-mode {
          display: flex;
          height: auto;
          overflow: auto;
          scroll-behavior: smooth;
          scroll-snap-type: x mandatory;

          .items {
            min-width: calc(100vw - 10px);
            scroll-snap-align: start;
          }
        }

				&.book-page-mode .items{
					display: flex;
					justify-content: center;
					height: auto;
					overflow: auto;
					background-color: white;

					.item{
						width: 65%;
					}

					.item.Back {
						width: 10%;
					}

					.item.Next {
						width: 10%;
					}
				}

        .item {
          width: auto;
			
					@media print {
						max-width: 100%;
						max-height: 100%;
					}

          li,
          .react-contextmenu-wrapper {
            display: flex;
            //flex-wrap: wrap;
            flex-direction: column;
            margin: 0;
            flex-grow: 1;

            .images {
              flex-grow: 1;
              flex-direction: column;
              width: 100%;
              justify-content: center;
              svg {
                display: block;
                height: calc(75vh / var(--vertical-items-ammount) - 50px);
              }
            }

            .image {
              max-height: none;
              max-width: none;
              min-height: auto;
              flex-grow: 1;
              width: 100%;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
