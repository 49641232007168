.parent-title {
  color: #999;
  font-size: 20px;
  margin: 10px 0px 10px 0px;
}

.card {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--background-box);
}

.card-content {
  padding: 16px;
}

.card-content h2 {
  margin-top: 0;
}

.card-content p {
  margin-bottom: 16px;
}

.card-content .button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 0.5fr 0.5fr; /* Three equal-width columns */
  grid-gap: 20px; /* Gap between grid items */
}

.column {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}


.dropdown {
  &.closed {
    height: 100px;
    margin-top: 15px;
  }
  
  &.open {
    height: 100px;
    margin-top: 15px;
  }
}