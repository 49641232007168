@import 'pallete.scss';

.home {
	display: flex;
	flex-grow: 1;

	img {
		max-width: 50ch;
		transform: scaleX(-1);
	}

	.tutorial {
		display: flex;
		background-color: #0007;
		margin: 10px auto;
		padding: 25px;
		color: #fff;

		.description {
			max-width: 65ch;
		}

		.mission {
			font-size: 30px;
			margin: 0;
		}

		p {
			font-size: 22px;
		}

		.bar {
			background-color: #0005;
			height: 10px;
			border-radius: 5px;
			margin: 0 0;
			display: flex;

			.fill {
				width: 80%;
				background-color: rgb(9, 191, 9);
				border-radius: 5px;
			}
		}

		.score {
			text-align: right;
			margin: 5px 5px;
			font-weight: bold;
			font-size: 18px;
			color: rgb(12, 228, 38);
		}
	}

	.btn-grid {
		margin: 0 auto;
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-template-rows: repeat(6, 1fr);
		gap: 30px;
		margin: 2vh auto;
		max-width: 1500px;
		height: calc(100vh - 120px);
		grid-template-areas:
			"play user"
			"play user"
			"play tutorial"
			"play tutorial"
			"store tutorial"
			"store settings";
		flex-grow: 1;

		.header {
			color: #fff;
			background-color: #0003;

			.user-card {
				margin: 20px;
				font-size: 22px;
				display: flex;

				span {
					display: block;
				}

				img {
					height: 40px;
					margin: 0 10px;
				}
			}
		}

		@include cellphone {
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: auto auto repeat(3, 1fr);
			margin: 20px;
			grid-template-areas:
				"user"
				"play"
				"tutorial"
				"store"
				"settings";

			svg {
				font-size: calc(11vw) !important;
			}

			span {
				font-size: 18px;
			}
		}

		.header {
			grid-area: header;
		}

		.btn-play {
			grid-area: play;
		}

		.btn-user {
			grid-area: user;
		}

		.btn-settings {
			grid-area: settings;
		}

		.btn-manual {
			grid-area: manual;
		}

		.btn-store {
			grid-area: store;
		}

		.btn-tutorial {
			grid-area: tutorial;
		}

		.btn-store {
			grid-area: store;
			color: #fff;
			background-color: #0003;
			padding: 20px;
			border-radius: 10px;

			.btn-header {
				font-size: 20px;

				svg {
					font-size: 20px;
					vertical-align: text-bottom;
				}
			}

			.boards {
				display: flex;
				flex-wrap: wrap;
				//justify-content: space-between;
				.board {
					width: 100px;
					margin: 0 10px;

					.Media {
						width: 67px;
						height: 60px;
						margin: 0 auto;
						background-color: #0003;
						border-radius: 10px;
					}

					.title {
						margin: 5px 0;
						font-size: 14px;
						text-align: center;
						padding: 0 5px;
					}

					.score {
						font-size: 14px;
						text-align: center;
						display: none;
					}
				}

				.description {
					display: none;
				}
			}

			a {
				display: block;
			}
		}

		.actions {
			display: flex;
		}

		.action {
			color: rgb(232, 232, 232);
			padding: 0;
			margin: 0;
			font-size: 18px;
			text-align: center;
			flex-grow: 0;
			flex-basis: 100px;
			background-color: transparent;

			.icon {
				background-color: #0003;
				display: inline-block;
				padding: 5px 15px;
				border-radius: 50%;
			}

			a {
				display: block;
				flex-grow: 1;
				margin: 0 auto;
			}

			svg {
				font-size: calc(2vh);
				display: block;
				margin: 10px auto;
			}

			span {
				display: block;
				margin: 0;
				font-weight: bold;
			}
		}

		.btn {
			background-color: #0003;
			color: rgb(232, 232, 232);
			padding: 0;
			margin: 0;
			border-radius: 10px;
			font-size: 24px;
			text-align: center;
			//display: flex;
			//flex-direction: column;

			a {
				display: block;
				flex-grow: 1;
				margin: 0 auto;
			}

			svg {
				font-size: calc(20px);
				display: block;
				margin: 10px auto;
			}

			span {
				display: block;
				margin: 20px 0 0;
				font-weight: bold;
			}
		}
	}
}
