.filter-area {
	display: flex;
	margin: 0 10px;

	.search-area {
		flex-grow: 1;
	}

	.language-picker {
		padding: 0 0 0 15px;

		select,
		option {
			background-color: var(--background-box);
			border-color: var(--border);
			color: var(--base-text);
			font-size: 18px;
			height: 40px;
		}
	}

	.search {
		display: block;
		font-size: 18px;
		padding: 9px;
		box-sizing: border-box;
		border: 1px solid;
		background-color: var(--background-box);
		border-color: var(--border);
		border-radius: 10px;
		margin: 0 auto;
		width: 100%;
	}
}

.search-results h2 {
	padding: 0 10px;
	margin: 22px 0 10px;
	font-weight: normal;
}
