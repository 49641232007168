@import 'pallete.scss';

.readjust-margin {
	margin: -10px 0 30px;
}

.featured-area {
	.carousel {
		max-width: 1050px;
		margin: 0 auto !important;

		@include mobile {
			max-width: 100vw;
		}
	}

	.featured {
		height: 300px;
		background-size: cover;
		background-position: center;
		background-position: 0 50%;
		text-shadow: 1px 1px 6px #000;
	}

	.description {
		text-align: right;
		margin: 0 40px 0 0;

		.title {
			font-size: 40px;
			padding: 40px 40px 10px;
			color: #fff !important;
		}

		.subtitle {
			font-size: 20px;
			padding: 10px 40px 0;
			max-width: 400px;
			margin-left: auto;
			word-break: break-word;
			color: #fff !important;
		}

		button {
			margin: 30px 40px 0;
			padding: 10px 40px;
		}
	}

	.featured-badge {
		float: left;
		background-color: rgba(0, 0, 0, 0.28);
		color: #fff;
		text-shadow: none;
		padding: 10px;
		margin: 12px;
		backdrop-filter: blur(5px);
		border-radius: 10px;

		svg {
			vertical-align: bottom;
		}
	}
}
