.ddl-wrapper {
  position: relative;
}

.ddl-container {
  position: absolute;
  background-color: var(--background-box);
  max-height: 0px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.ddl-container.visible {
  max-height: 120px;
}

.ddl-item {
  padding: 0.5em;
  color: var(--base-text);
  width: 100%;
  text-align: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    user-select: none;
  }
}
