.admin-area {
	.promote-btn button span,
	.delete button span {
		display: none;
	}

	.admin-header {
		.picker {
			list-style-type: none;
			display: flex;
			padding: 0;
			border-bottom: 1px solid var(--border);
			color: var(--button-brand);

			li {
				border-radius: 10px;
				margin: 0;
				flex: 1 1 0;
				font-size: 1.2em;

				a {
					display: block;
					padding: 10px;
					text-align: center;

					&.active {
						//background-color: var(--border-brand);
						//color: #fff;
						border-bottom: 4px solid var(--border-brand);
					}
				}

				svg {
					padding: 0 7px;
					vertical-align: text-bottom;
				}
			}
		}
	}

	.stats {
		.section {
			display: flex;
			flex-wrap: wrap;

			.number {
				background-color: var(--background-box);
				border: 1px solid var(--border);
				border-radius: 5px;
				padding: 10px;
				margin: 10px;
				flex-basis: 0;
				flex-grow: 1;

				.title {
					font-size: 1em;
					margin: 0;
					padding: 0;
					color: var(--base-subtext);
				}

				.count {
					font-size: 1.7em;
					padding: 5px 0 0;

					.detail {
						font-size: 0.5em;
						font-weight: normal;
						display: inline-block;
						padding: 0 5px;
						color: var(--base-subtext);
					}
				}
			}
		}

		.symbol-usage {
			display: flex;
			flex-wrap: wrap;

			.symbol {
				display: flex;
				background-color: var(--background-box);
				border: 1px solid var(--border);
				margin: 0 5px;
				align-items: center;
				padding: 5px;
				border-radius: 5px;
			}

			.Media {
				width: 30px;
				height: 30px;
				margin: 0 5px;
				border-radius: 0;
			}
		}
	}
}
