@import 'pallete.scss';

.livox-store .board {
	flex-basis: calc(20% - 30px);
	margin: 10px 10px;
	padding: 10px 5px;
	border-radius: 10px;
	box-shadow: 0 1px 2px 1px var(--background-accent);
	background-color: #fff;
	transition: 0.2s all cubic-bezier(0.89, 0.02, 1, 0.84);
	background-color: var(--background-box);
	border-color: var(--border);
	color: var(--base-text);

	@include mobile {
		flex-basis: 100%;
		margin: 4px 1px;
		display: flex;

		.info {
			flex-grow: 1;

			.title {
				text-align: initial;
			}
		}

		div.img,
		div.image {
			margin: 0;
			align-self: center;
		}
	}

	&:hover {
		box-shadow: 0 2px 3px 2px #4b4b4b20;
	}

	.img {
		margin: 0 auto 5px;
		flex-shrink: 0;

		.image {
			width: 110px;
			height: 90px;
			margin: 0 auto;
			background-size: cover;
			background-position: 50%;
			background-repeat: no-repeat;
		}
	}

	.info {
		padding: 0 15px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.title {
		margin: 0;
		padding: 6px 0 6px;
		font-size: 16px;
		//font-weight: normal;
		//text-align: center;
		color: var(--dark-brand);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.description {
		margin: 3px 0 5px;
		color: var(--base-text);
		height: 42px;
		font-size: 14px;
		overflow: hidden;
		word-break: break-word;
		//display: none;
	}

	.score {
		display: flex;
		//justify-content: space-between;
		margin: 5px 0;
		min-height: 20px;

		.likes {
			padding: 2px 0;

			span {
				font-size: 20px;
			}

			svg {
				color: #fbbc04;
				fill: #fbbc04;
				vertical-align: text-bottom;
			}
		}

		.no-data {
			font-size: 12px;
		}

		.downloads {
			color: var(--base-brand);
			margin: 0 12px;
		}
	}

	.author {
		color: var(--base-subtext);
		font-size: 13px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.actions {
		button {
			margin: 0 1px;
			background: transparent;
			color: var(--base-subtext);

			&:hover {
				color: var(--border-brand);
			}
		}
	}
}
