@import 'pallete.scss';

.store-container {
	background-color: var(--background-accent-store);
	//min-height: calc(100vh - 63px);
}

.livox-store {
	max-width: 1100px;
	margin: 0 auto;
	padding: 30px 15px;

	.boards {
		display: flex;
		overflow: hidden;
		flex-wrap: wrap;
	}

	.language {
		text-align: end;
		font-size: 16px;
		margin: 1px;
		opacity: 0.89;
		margin-top: -20px;

		img {
			height: 17px;
		}
	}

	.more-items {
		padding: 20px;
		font-size: 22px;
		text-align: right;
		cursor: pointer;

		svg {
			vertical-align: middle;
		}
	}

	.table-control,
	.pagination {
		display: none;
	}

	.page-data {
		margin: 0 0 15px;
	}
}
