@import 'pallete.scss';

.livox-app .setup {
	width: 700px;
	margin: 8vh auto;
	background-color: #fff;
	box-shadow: 1px 3px 5px 0 rgba(35, 35, 35, 0.56);
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

	.form-creation {
		padding: 0;
	}

	.mobile-back {
		display: none;
	}

	@include cellphone {
		max-width: 700px;
		width: auto;
		margin: 0;

		.wizard {
			background-color: var(--button-brand);
			color: #fff;
			display: flex;
			align-items: center;

			.mobile-back {
				font-size: 24px;
				padding: 2px 0 4px 4px;
				display: block;
			}
		}

		.wizard .steps {
			ol {
				display: block !important;
			}

			li {
				text-align: left !important;
				pointer-events: none;
			}

			a {
				font-size: 21px;
			}

			a:not(.active) {
				display: none;
			}

			svg {
				display: none;
			}

			a.active {
				border-bottom: none !important;
				color: #fff;
			}
		}
	}

	.wizard {
		.steps ol {
			padding: 0;
			margin: 0;
			list-style-type: none;
			display: flex;

			li {
				flex-basis: 0;
				flex-grow: 1;
				text-align: center;
				margin: 0 5px;

				&.disabled {
					pointer-events: none;

					> * {
						color: rgb(138, 138, 138);
						font-weight: bold;
					}
				}
			}

			a {
				color: var(--button-brand);
				padding: 15px 15px 10px;
				display: block;

				&.active {
					border-bottom: 3px solid var(--button-brand);
				}
			}

			svg {
				align-self: center;
			}
		}
	}

	.content {
		padding: 20px;
	}

	.btn-area {
		display: flex;
		justify-content: space-between;

		button {
			padding: 10px 20px 8px;
			font-size: 20px;
		}
	}

	.welcome-message {
		margin: 0 auto;

		img {
			margin: 0 auto;
			display: block;
		}

		h2 {
			margin: 10px 0 5px;
			font-weight: normal;
			font-size: 30px;
			text-align: center;
		}

		.hype-text {
			margin: 0;
			color: var(--base-subtext);
			text-align: center;
		}

		p {
			font-size: 18px;
		}
	}

	button {
		margin: 0;
	}
}
