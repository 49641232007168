.livox-studio {
	.react-contextmenu-wrapper {
		padding: 0 !important;
		max-height: 100%;
	}

	.item-menu {
		background-color: var(--background-box);
		border: 1px solid transparent;
		border-radius: 6px;
		box-shadow: 0 2px 6px 2px var(--hover-accent);
		min-width: 150px;
		user-select: none;

		.react-contextmenu-item {
			padding: 7px 10px;
			cursor: pointer;
			display: flex;
			justify-content: space-between;

			.shortcut {
				color: var(--base-subtext);
				font-size: 14px;
				font-family: monospace;
			}

			&:hover {
				background-color: var(--hover-accent);
			}

			svg {
				padding: 0 5px 0 0;
				vertical-align: text-bottom;
			}

			&.disabled,
			&.react-contextmenu-item--disabled {
				color: var(--disabled);

				.shortcut {
					color: var(--disabled);
				}

				cursor: auto;

				&:hover {
					background-color: transparent;
				}
			}
		}

		.react-contextmenu-item--divider {
			cursor: auto !important;
			padding: 0;
			border-bottom: 1px solid var(--border);
		}
	}
}
