@import 'pallete.scss';

.back-link {
	color: rgb(0, 74, 97);

	svg {
		vertical-align: top;
		margin: 0 2px 0 0;
	}
}

.profile-view {
	max-width: 1200px;
	margin: 0 auto;
	display: flex;

	.board {
		flex-basis: calc(25% - 40px);
	}

	.store-sidebar {
		margin: 30px 0 0;
		flex-basis: 170px;
		flex-shrink: 0;
		flex-grow: 0;
		padding-right: 20px;

		@include mobile {
			flex-wrap: wrap;

			.image img {
				width: 120px !important;
				height: 120px !important;
			}

			.actions {
				width: 100%;
				margin: 10px 0 0;
				display: flex;
				justify-content: space-evenly;
			}
		}

		h4 {
			margin: 15px 0 5px;
		}

		.metadata {
			flex-grow: 1;
		}

		.Media {
			width: 170px;
			height: 170px;
			border-radius: 20px;
			border: 1px solid var(--border);
			display: block;
			background-color: #fff;
		}

		.title {
			margin: 9px 0 3px;
		}

		.author {
			display: block;
			font-size: 17px;
			margin: 0 0 3px;
		}

		.badges {
			.badge {
				font-size: 14px;
				padding: 7px 10px;
			}
		}
	}

	.content {
		flex-grow: 1;
		padding-left: 60px;

		.header {
			display: flex;
			justify-content: space-between;
			margin: 0 0 5px;

			.user-data {
				word-break: break-word;
				padding-right: 20px;
			}

			.action {
				flex-shrink: 0;
			}

			.title {
				font-size: 40px;
				margin: 20px 0 5px;
			}

			.edit {
				align-self: center;
				margin: 10px 0;

				button {
					margin: 0;
					font-size: 18px;
				}
			}
		}
	}

	.description {
		font-size: 19px;
		line-height: 1.35;
		white-space: pre-line;
	}
}
